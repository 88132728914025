import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery, rawToCollectionResponse } from './functions';
import { TeamMember } from '../components/Settings/types';
import { CollectionResponse, RawCollectionResponse } from './models';
import { FeedbackParams } from './types';

export type UpdateRolesArgs = {
  id: number;
  teamMemberRoles: string[];
} & FeedbackParams;

export type AddMemberArgs = {
  firstname: string;
  lastname: string;
  email: string;
  plainPassword: string;
  confirmPassword: string;
} & FeedbackParams;

export type EditMemberArgs = {
    id: number;
} & AddMemberArgs & FeedbackParams;

export type Toggle2FAArgs = {
  id: number;
  enable2Fa: boolean;
} & FeedbackParams;


export const teamMemberApi = createApi({
  baseQuery: buildBaseQuery(),
  reducerPath: 'teamMemberApi',
  endpoints: (builder) => ({
    getMembers: builder.query<CollectionResponse<TeamMember>, FeedbackParams>({
      query: () => ({
        url: '/users/team-members',
      }),
      transformResponse: (response: RawCollectionResponse<TeamMember>) => rawToCollectionResponse(response),
    }),
    addMember: builder.mutation<TeamMember, AddMemberArgs>({
      query: ({ firstname, lastname, email, plainPassword, confirmPassword}) => ({
        url: `/users/team-members`,
        method: 'POST',
        body: { firstname, lastname, email, plainPassword, confirmPassword },
      }),
    }),
    editMember: builder.mutation<TeamMember, EditMemberArgs>({
      query: ({ id, firstname, lastname, email, plainPassword, confirmPassword}) => ({
        url: `/users/${id}/team-members`,
        method: 'PUT',
        body: { firstname, lastname, email, plainPassword, confirmPassword },
      }),
    }),
    updateRoles: builder.mutation<TeamMember, UpdateRolesArgs>({
      query: ({ id, teamMemberRoles }) => ({
        url: `/users/${id}/roles`,
        method: 'PUT',
        body: { teamMemberRoles },
      }),
    }),
    deleteMember: builder.mutation<TeamMember, { id: number } & FeedbackParams>({
      query: ({ id }) => ({
        url: `/users/${id}/team-members`,
        method: 'DELETE',
      }),
    }),
    suspendMember: builder.mutation<TeamMember, { id: number } & FeedbackParams>({
      query: ({ id }) => ({
        url: `/users/${id}/suspend`,
        method: 'PUT',
        body: {}
      }),
    }),
    unsuspendMember: builder.mutation<TeamMember, { id: number } & FeedbackParams>({
      query: ({ id }) => ({
        url: `/users/${id}/unsuspend`,
        method: 'PUT',
        body: {}
      }),
    }),
    toggle2FA: builder.mutation<TeamMember, Toggle2FAArgs>({
      query: ({ id, enable2Fa}) => ({
        url: `/users/${id}/team-members/2fa`,
        method: 'PUT',
        body: { enable2Fa },
      }),
    }),
    reset2FA: builder.mutation<TeamMember, { id: number } & FeedbackParams>({
      query: ({ id}) => ({
        url: `/users/${id}/team-members/reset-2fa`,
        method: 'PUT',
        body: {}
      }),
    }),
  }),
});

export const {
  useGetMembersQuery,
  useAddMemberMutation,
  useEditMemberMutation,
  useUpdateRolesMutation,
  useDeleteMemberMutation,
  useSuspendMemberMutation,
  useUnsuspendMemberMutation,
  useToggle2FAMutation,
  useReset2FAMutation,
} = teamMemberApi;
