import axios from 'axios';
import {
  API_V2_GET_USER_DETAILS,
  API_V2_SUSPEND_USER,
  API_V2_UNSUSPEND_USER,
  API_V2_SEND_GUEST_EMAIL,
  API_V2_SEND_RESET_PASSWORD,
  API_V2_GET_CHECK_EXISTING_EMAIL,
  API_V2_TEAM_MEMBERS_URL,
} from '../constants/urls';
import {get, put, deleteItem} from './api';

// fetch user details from dummy json api
// add fields that don't exist
export async function getUserDetails({id, token}) {
  const data = await get({
    url: API_V2_GET_USER_DETAILS.replace(':id', id),
    token,
    ldjson: true,
    config: {},
  });

  return data;
}

// update user details
export async function updateUserDetail({token, ...payload}) {
  const body = {...payload};
  delete body.userId;
  const data = await put({
    url: API_V2_GET_USER_DETAILS.replace(':id', payload.userId),
    body,
    ldjson: true,
    token,
  });

  return data;
}

// suspend user
export async function suspendUser(payload) {
  const data = await put({
    url: API_V2_SUSPEND_USER.replace(':id', payload.userId),
    ldjson: true,
    token: payload.token,
    body: {}
  });

  return data;
}

// unsuspend user
export async function unSuspendUser(payload) {
  const data = await put({
    url: API_V2_UNSUSPEND_USER.replace(':id', payload.userId),
    ldjson: true,
    token: payload.token,
    body: {}
  });

  return data;
}

// Send Guest Email to setup his password
export async function sendGuestEmail(payload) {
  const data = await put({
    url: API_V2_SEND_GUEST_EMAIL.replace(':id', payload.userId),
    ldjson: true,
    token: payload.token,
    body: {},
  });

  return data;
}

// Send Reset Password Email
export async function sendResetPasswordEmail(payload) {
  const data = await put({
    url: API_V2_SEND_RESET_PASSWORD.replace(':id', payload.userId),
    ldjson: true,
    token: payload.token,
    body: {},
  });

  return data;
}

// Delete user from the database
export async function deleteUser(payload) {
  const url = API_V2_GET_USER_DETAILS.replace(':id', payload.userId);
  await axios.delete(url);
  return true;
}

// update user details
export async function checkExistingEmail(payload) {
  const data = await get({
    url: API_V2_GET_CHECK_EXISTING_EMAIL.replace(':id', payload.id).replace(
      ':email',
      payload.email
    ),
    ldjson: true,
    token: payload.token,
    config: {}
  });
  return data;
}

export async function listTeamMembers(payload) {
  return await get({
    url: API_V2_TEAM_MEMBERS_URL,
    ldjson: true,
    token: payload.token,
    config: {}
  });
}
