import {Fragment, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {reduxForm, reset} from 'redux-form';
import {useDispatch, useSelector} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {Box, CircularProgress} from '@material-ui/core';
import get from 'lodash.get';
import {useHistory, useParams} from 'react-router-dom';

import ArrowLink from '../../Common/ArrowLink';
import * as actionTypes from '../../../constants/actionTypes';
import * as urls from '../../../constants/urls';
import useSaleDetails from '../../../hooks/sale/useSaleDetails';
import UserInformation from './UserInformation';
import RefundPopup from './Popups/RefundPopup';
import AddPenaltyPopup from './Popups/AddPenaltyPopup';
import PaymentPopup from './Popups/PaymentPopup';
import PayoutPopup from '../PayoutPopup';
import PermissionContainer from '../../Common/PermissionContainer';
import CollapsableBlock from '../../Common/CollapsableBlock';
import {
  ADMIN_TRANSACTION_DETAILS_HYPERLINKS,
  BREAKDOWN_FEES_PERMISSIONS,
  SALES_SUMMARY_PERMISSIONS,
} from '../../../constants/permissions';
import MticketBlock from '../List/MticketBlock';
import EticketBlock from '../List/EticketBlock';
import SalesSummaryRow from './SalesSummaryRow';
import SaleDetailsHeader from './SaleDetailsHeader';
import SaleListingDetails from './SaleListingDetails';
import PaperTicketBlock from '../List/PaperTicketBlock';
import ConfirmationPopup from './Popups/ConfirmationPopup';
import {formatCurrencyValue} from '../../../utils/functions';
import clsx from 'clsx';
import {cloneDeep} from 'lodash';
import {getSellingPrice} from '../../../utils/saleHelper';
import ProposePenaltyRefundPopup from './Popups/ProposePenaltyRefundPopup';
import {TRANSACTION_REFUND_REASONS} from '../../../constants/transactions';
import usePermission from '../../../Auth/usePermission';
import SaleNotes from './SaleNotes';
import {NoteEntity} from '../../../types/common/NoteEntity';

const validate = () => {
  return {};
};

const sortPenaltiesByDateDesc = penalties => {
  const res = cloneDeep(penalties ?? []);
  res.sort((p1, p2) => {
    const d1 = new Date(p1.createdAt ?? '');
    const d2 = new Date(p2.createdAt ?? '');

    return p1.createdAt === p2.createdAt ? 0 : d1 < d2 ? -1 : 1;
  });

  return res;
};
/*
   Sales Details
*/
let SalesDetails = props => {
  const intl = useIntl();
  const dispatch = useDispatch();
  //custom shipment is on
  const [custom, setCustom] = useState(false);
  //custom shipment is shipped
  const {saleId: id} = useParams();
  const saleDetails = useSaleDetails(id);
  const {hasAnyPermission} = usePermission();

  const {
    sale,
    sellerPaymentMethods,
    ticket,
    showPaperDelivery,
    showMticketDelivery,
    showEticketDelivery,
    notFound,
    showUserAddress,
    fetchSaleDetails,
  } = saleDetails;

  const history = useHistory();
  //Cancel popup
  const [cancelPopup, setCancelPopup] = useState(false);
  //Set as cancelled popup
  const [asCancelledPopup, setAsCancelledPopup] = useState(false);
  //Refund popup
  const [refundPopup, setRefundPopup] = useState(false);
  //Penalty popup
  const [penaltyPopup, setPenaltyPopup] = useState(false);
  //New payment popup
  const [paymentPopup, setPaymentPopup] = useState(false);
  // Pay out to bank
  const [payoutPopup, setPayoutPopup] = useState(false);
  //Set as delivered popup
  const [asDeliveredPopup, setAsDeliveredPopup] = useState(false);
  //Propose Penalty after refund dialog
  const [proposePenaltyShown, setProposePenaltyShown] = useState(false);
  const {refundAdded, penaltyAdded, sellerChargeCreated} = useSelector(
    store => store.saleReducer
  );

  if (notFound) {
    history.push(urls.SALES_LIST_PATH);
  }

  //Amounts
  const {
    sellerPaymentAmount = 0,
    sellerPaymentCount = 0,
    referenceHexa,
    payout,
  } = sale || {};
  const sellerPaymentAmountFormatted = `£${formatCurrencyValue(sellerPaymentAmount)}`;

  //Close add Refund popup when refund added
  useEffect(() => {
    if (refundAdded) {
      setRefundPopup(false);
      dispatch(reset('RefundPopupForm'));
      setProposePenaltyShown(true);
    }
  }, [refundAdded, dispatch]);

  //Close add Penalty popup when penalty added
  useEffect(() => {
    if (penaltyAdded) {
      setPenaltyPopup(false);
      dispatch(reset('AddPenaltyPopupForm'));
    }
  }, [penaltyAdded, dispatch]);

  //Close Payment popup when payment added
  useEffect(() => {
    setPaymentPopup(false);
    dispatch(reset('PaymentPopupForm'));
  }, [sellerChargeCreated, dispatch]);

  const currency = (value, currencyCode) => {
    const code =
      currencyCode || get(sale, 'data.currency', ticket && ticket.currency);

    return code && value !== null
      ? intl.formatNumber(value, {
          style: 'currency',
          currency: code,
        })
      : value;
  };

  //Cancel sales
  const onCancelSales = () => {
    setCancelPopup(false);
    dispatch({
      type: actionTypes.API_ADMIN_SALE_CANCEL_REQUESTED,
      payload: {
        sale,
      },
    });
  };

  //Set sales as cancelled
  const onSetAsCancelledSales = () => {
    setAsCancelledPopup(false);
    dispatch({
      type: actionTypes.API_ADMIN_SALE_SET_AS_CANCELLED_REQUESTED,
      payload: {
        sale,
      },
    });
  };

  //Set sales as delivered
  const onSetAsDeliveredSales = () => {
    setAsDeliveredPopup(false);
    dispatch({
      type: actionTypes.API_ADMIN_SALE_SET_AS_DELIVERED_REQUESTED,
      payload: {
        sale,
      },
    });
  };

  // When click on "Add Penalty" from the Propose Penalty Popup, Show Penalty Popup
  const onAddPenalty = () => {
    // Hide Propose Penalty Popup
    setProposePenaltyShown(false);
    // Show Penalty Popup
    setPenaltyPopup(true);
  };

  //Pause payout for a sale
  const onPausePayout = () => {
    dispatch({
      type: actionTypes.API_ADMIN_SALE_PAUSE_PAYOUT_REQUESTED,
      payload: {
        sale,
      },
    });
  };

  //Resume payout for a sale
  const onResumePayout = () => {
    dispatch({
      type: actionTypes.API_ADMIN_SALE_RESUME_PAYOUT_REQUESTED,
      payload: {
        sale,
      },
    });
  };

  //Sale summary link
  const printSalesSummaryLink = ({
    title,
    count,
    value,
    url,
    valueClassName = '',
  }) => (
    <div className="row gx-1 pt-2 pb-1">
      <div className="col">
        <ArrowLink
          url={hasAnyPermission(ADMIN_TRANSACTION_DETAILS_HYPERLINKS) && url}
          title={title}
        />
      </div>
      <div className="col-auto">
        <Typography variant="body2" className={valueClassName}>
          {value}
        </Typography>
      </div>
    </div>
  );

  // Summary collapse block item element
  const printSummaryCollapseItem = ({label, value}) => (
    <div className="row gx-1 pt-2 pb-1">
      <div className="col">
        <Typography variant="body2" className="text-muted">
          {label}
        </Typography>
      </div>
      <div className="col-auto">
        <Typography variant="body2" className="text-muted">
          {value}
        </Typography>
      </div>
    </div>
  );

  //Example of showing Alerts
  const showAlerts = () => {
    dispatch({
      type: actionTypes.ENQUEUE_SNACKBAR_FROM_COMPONENT,
      payload: {
        messages: intl.formatMessage({
          id: 'dashboard.sales.details.sales_has_been_paused',
        }),
        variant: 'success',
      },
    });
    dispatch({
      type: actionTypes.ENQUEUE_SNACKBAR_FROM_COMPONENT,
      payload: {
        messages: intl.formatMessage({
          id: 'dashboard.sales.details.sales_payout_hasnt_been_resumed',
        }),
        variant: 'error',
      },
    });
  };

  //Amount
  const sellingPrice = useMemo(() => getSellingPrice(sale), [sale]);

  const currentSalePenalties = useMemo(() => {
    if (!sale) {
      return [];
    }

    return sortPenaltiesByDateDesc(
      (sale?.penalties ?? []).filter(p => p.currentAmount > 0)
    );
  }, [sale]);

  const futureSalePenalties = useMemo(() => {
    if (!sale) {
      return [];
    }

    return sortPenaltiesByDateDesc(
      (sale?.penalties ?? []).filter(p => p.futureAmount > 0)
    );
  }, [sale]);

  // Retrieve reason label based on reason value to be displayed refunds list
  const getReasonLabel = reason => {
    for (let i = 0; i < TRANSACTION_REFUND_REASONS.length; i++) {
      if (TRANSACTION_REFUND_REASONS[i].value === reason) {
        return TRANSACTION_REFUND_REASONS[i].label;
      }
    }

    return null;
  };

  const [notes, setNotes] = useState<NoteEntity[]>(sale.notes);

  useEffect(() => setNotes(sale.notes), [sale]);

  if (!sale) {
    return (
      <Box
        height="100vh"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      {/*Refund popup*/}
      <RefundPopup
        opened={refundPopup}
        handleClose={() => setRefundPopup(false)}
        sale={sale}
      />
      {/*Add penalty popup*/}
      <AddPenaltyPopup
        opened={penaltyPopup}
        handleClose={() => setPenaltyPopup(false)}
        sale={sale}
      />
      {/*Create new payment*/}
      <PaymentPopup
        opened={paymentPopup}
        handleClose={() => setPaymentPopup(false)}
        sale={sale}
        sellerPaymentMethods={sellerPaymentMethods}
      />
      {/*Pay out to bank*/}
      <PayoutPopup
        opened={payoutPopup}
        handleClose={() => setPayoutPopup(false)}
      />
      {/*Cancel Sale Popup*/}
      <ConfirmationPopup
        open={cancelPopup}
        setOpen={setCancelPopup}
        onAccept={onCancelSales}
        description={intl.formatMessage({
          id: 'dashboard.sales.details.cancel_sales_message',
        })}
      />
      {/*Set as cancelled Popup*/}
      <ConfirmationPopup
        open={asCancelledPopup}
        setOpen={setAsCancelledPopup}
        onAccept={onSetAsCancelledSales}
        description={intl.formatMessage({
          id: 'dashboard.sales.details.set_as_cancelled_message',
        })}
      />
      {/*Set as delivered Popup*/}
      <ConfirmationPopup
        open={asDeliveredPopup}
        setOpen={setAsDeliveredPopup}
        onAccept={onSetAsDeliveredSales}
        description={intl.formatMessage({
          id: 'dashboard.sales.details.set_as_delivered_message',
        })}
      />
      {/*Propose Penalty after refund popup*/}
      <ProposePenaltyRefundPopup
        opened={proposePenaltyShown}
        handleClose={() => setProposePenaltyShown(false)}
        onAccept={onAddPenalty}
      />

      <div className="details-page sale">
        {/*Top Header*/}
        <SaleDetailsHeader
          setCustom={setCustom}
          showAlerts={showAlerts}
          setRefundPopup={setRefundPopup}
          setPenaltyPopup={setPenaltyPopup}
          setPaymentPopup={setPaymentPopup}
          setCancelPopup={setCancelPopup}
          setAsCancelledPopup={setAsCancelledPopup}
          setAsDeliveredPopup={setAsDeliveredPopup}
          sale={sale}
          sellerPaymentMethods={sellerPaymentMethods}
          onPausePayout={onPausePayout}
          onResumePayout={onResumePayout}
        />

        {/*Content*/}
        <div className="container ms-lg-0">
          <div className="content-block">
            <div className="row gx-3 flex-column flex-lg-row flex-column-reverse">
              <div className="col-lg-8">
                <SaleListingDetails saleDetails={saleDetails} />
                {showMticketDelivery && (
                  <MticketBlock saleDetails={saleDetails} custom={custom} />
                )}
                {showEticketDelivery && (
                  <EticketBlock saleDetails={saleDetails} custom={custom} />
                )}
                {showPaperDelivery && (
                  <PaperTicketBlock saleDetails={saleDetails} />
                )}
                <div className="row gx-3 mt-3">
                  <div className="col-lg-6">
                    <div className="mb-3 mb-lg-0 h-100">
                      <UserInformation
                        user={sale?.seller || {}}
                        type="seller"
                        address={sale?.shippingFrom}
                        showAddress={showUserAddress}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <UserInformation
                      user={sale?.buyer || {}}
                      type="buyer"
                      address={sale?.shippingTo}
                      showAddress={showUserAddress}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <PermissionContainer permissions={SALES_SUMMARY_PERMISSIONS}>
                  <div className="card details-card sales-summary mx-0 mb-3">
                    <div className="card-body pb-3">
                      <Typography variant="h4" className="mb-1 pb-1">
                        {intl.formatMessage({
                          id: 'dashboard.sales.details.sales_summary',
                        })}
                      </Typography>
                      <PermissionContainer
                        permissions={BREAKDOWN_FEES_PERMISSIONS}
                      >
                        <SalesSummaryRow
                          label={intl.formatMessage({
                            id: 'dashboard.sales.details.selling_price',
                          })}
                          value={currency(sellingPrice)}
                        />
                        <SalesSummaryRow
                          label={intl.formatMessage({
                            id: 'dashboard.sales.details.handling_fee',
                          })}
                          value={currency(sale.fullHandlingFees ?? 0)}
                        />
                      </PermissionContainer>
                      <SalesSummaryRow
                        label={intl.formatMessage({
                          id: 'dashboard.sales.details.total',
                        })}
                        value={currency(sale.totalWithCharges ?? 0)}
                        bold
                      />
                      <hr className="text-hightlight mt-2 mb-1" />
                      {/* Total refund */}
                      <CollapsableBlock
                        header={
                          <Typography variant="body2" className="text-truncate">
                            {`${intl.formatMessage({id: 'dashboard.sales.details.total_refund'})} (${sale.refundsCount ?? 0})`}
                          </Typography>
                        }
                        restContent={
                          <Typography variant="body2">
                            {currency(sale.platformRefundsAmount ?? 0)}
                          </Typography>
                        }
                      >
                        {/* LIST of all Refund on selling price (one line per refund) */}
                        {sale.platformRefunds &&
                          sale.platformRefunds.map(
                            platformRefund =>
                              // only display when amount is >= 0.01
                              platformRefund.sellingAmount >= 0.01 &&
                              printSummaryCollapseItem({
                                label:
                                  intl.formatMessage({
                                    id: 'dashboard.sales.details.refund_selling_price',
                                  }) +
                                  ' - ' +
                                  getReasonLabel(platformRefund.reason),
                                value: currency(platformRefund.sellingAmount),
                              })
                          )}
                        {/* LIST of all Refund on handling fees (one line per refund) */}
                        {sale.platformRefunds &&
                          sale.platformRefunds.map(
                            platformRefund =>
                              // only display when amount is >= 0.01
                              platformRefund.handlingAmount >= 0.01 &&
                              printSummaryCollapseItem({
                                label:
                                  intl.formatMessage({
                                    id: 'dashboard.sales.details.refund_handling_fees',
                                  }) +
                                  ' - ' +
                                  getReasonLabel(platformRefund.reason),
                                value: currency(platformRefund.handlingAmount),
                              })
                          )}
                      </CollapsableBlock>
                      {/* Penalty on current sale */}
                      <CollapsableBlock
                        header={
                          <Typography variant="body2">
                            {`${intl.formatMessage({id: 'dashboard.sales.details.penalty_current_sale'})} (${currentSalePenalties.length})`}
                          </Typography>
                        }
                        restContent={
                          <Typography
                            variant="body2"
                            className={clsx({
                              'text-fan-rose': sale?.currentPenaltyAmount > 0,
                            })}
                          >
                            {currency(sale?.currentPenaltyAmount)}
                          </Typography>
                        }
                      >
                        {currentSalePenalties.map(penalty => (
                          <Fragment key={penalty.id}>
                            {printSummaryCollapseItem({
                              label: 'Penalty',
                              value: currency(penalty.currentAmount),
                            })}
                          </Fragment>
                        ))}
                      </CollapsableBlock>
                      {printSalesSummaryLink({
                        title: `${intl.formatMessage({id: 'dashboard.sales.details.payout'})} (${payout ? 1 : 0})`,
                        value: currency(
                          sale.payoutAmountWithoutFuturePenalties ?? 0
                        ),
                        count: payout ? 1 : 0,
                        url: `${urls.TRANSACTIONS_PAYOUT_PATH}?formattedFilters=%7B"salesHex"%3A"${referenceHexa}"%7D&filters=%7B"salesHex"%3A"${referenceHexa}"%7D`,
                      })}
                      <hr className="text-hightlight mt-2 mb-1" />
                      <CollapsableBlock
                        header={
                          <Typography variant="body2">
                            {`${intl.formatMessage({id: 'dashboard.sales.details.penalty_future_sale'})} (${futureSalePenalties.length})`}
                          </Typography>
                        }
                        restContent={
                          <Typography variant="body2">
                            {currency(sale?.futurePenaltyAmount ?? 0)}
                          </Typography>
                        }
                      >
                        {(futureSalePenalties ?? []).map(penalty => (
                          <Fragment key={penalty.id}>
                            {printSummaryCollapseItem({
                              label: 'Penalty',
                              value: currency(penalty?.futureAmount),
                            })}
                          </Fragment>
                        ))}
                      </CollapsableBlock>
                      <hr className="text-hightlight mt-2 mb-1" />
                      <div className="pb-1">
                        {printSalesSummaryLink({
                          title: `${intl.formatMessage({id: 'dashboard.sales.details.seller_charge'})} (${sellerPaymentCount})`,
                          value: sellerPaymentAmountFormatted,
                          count: sellerPaymentCount,
                          url: `${urls.TRANSACTIONS_PAYMENT_PATH}?filters={"saleHex":"${referenceHexa}","resourceType":["seller-charge"]}`,
                        })}
                      </div>
                    </div>
                  </div>
                </PermissionContainer>
                <SaleNotes
                  notes={notes}
                  saleId={sale.id}
                  onNoteUpdated={() => fetchSaleDetails(id)}
                  onNoteAdded={newNote => {
                    setNotes([...notes, newNote]);
                    fetchSaleDetails(id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

//Wrap component with Redux Form
SalesDetails = reduxForm({
  form: 'salesDetailsForm',
  enableReinitialize: true,
  validate,
})(SalesDetails);

//Export
export default SalesDetails;
