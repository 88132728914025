export const STATUS_DELIVERY_PENDING = 'delivery-pending';
export const STATUS_COLLECTION_PENDING = 'collection-pending';
export const STATUS_CANCELLED = 'cancelled';
export const STATUS_CONFIRMATION_PENDING = 'confirmation-pending';
export const STATUS_DELIVERY_MISSED = 'delivery-missed';
export const STATUS_ADDRESS_NOT_COLLECTED = 'address-not-collected';
export const STATUS_DELIVERED = 'delivered';
export const STATUS_TERMINATED = 'terminated';
export const STATUS_ANNULLED = 'annulled';
export const STATUS_UPLOAD_PENDING = 'upload-pending';
export const STATUS_TRANSFER_PENDING = 'transfer-pending';

// This are the status allowed to make visible the add penalty action in the action dropdown
export const PENALTY_STATUS_ALLOWED = [
  STATUS_COLLECTION_PENDING,
  STATUS_DELIVERY_PENDING,
  STATUS_ADDRESS_NOT_COLLECTED,
  STATUS_DELIVERY_MISSED,
  STATUS_DELIVERED,
  STATUS_TERMINATED,
  STATUS_ANNULLED,
  STATUS_CANCELLED,
];

// This are the status allowed to make visible the add penalty action in the action dropdown
export const REFUND_STATUS_ALLOWED = [
  STATUS_COLLECTION_PENDING,
  STATUS_DELIVERY_PENDING,
  STATUS_ADDRESS_NOT_COLLECTED,
  STATUS_DELIVERY_MISSED,
  STATUS_DELIVERED,
  STATUS_TERMINATED,
  STATUS_ANNULLED,
];

// This are the status allowed to make visible the create payment action in the action dropdown
export const CREATE_PAYMENT_STATUS_ALLOWED = [
  STATUS_COLLECTION_PENDING,
  STATUS_DELIVERY_PENDING,
  STATUS_ADDRESS_NOT_COLLECTED,
  STATUS_DELIVERY_MISSED,
  STATUS_DELIVERED,
  STATUS_TERMINATED,
  STATUS_ANNULLED,
  STATUS_CANCELLED,
];

//List of status allowed for sale to be set as cancelled
export const SET_AS_CANCELLED_STATUS_ALLOWED = [
  STATUS_COLLECTION_PENDING,
  STATUS_DELIVERY_PENDING,
  STATUS_ADDRESS_NOT_COLLECTED,
  STATUS_DELIVERY_MISSED,
  STATUS_DELIVERED,
  STATUS_TERMINATED,
];

//List of status allowed for sale to be set as delivered
export const SET_AS_DELIVERED_STATUS_ALLOWED = [
  STATUS_COLLECTION_PENDING,
  STATUS_DELIVERY_PENDING,
  STATUS_DELIVERY_MISSED,
];

export const SALE_FILTER_STATUS_CHOICES = [
  STATUS_ADDRESS_NOT_COLLECTED, // Address not collected
  STATUS_ANNULLED, // Cancelled
  STATUS_COLLECTION_PENDING, // Collection pending
  STATUS_TERMINATED, // Completed
  STATUS_CANCELLED, // Confirmation missed
  STATUS_CONFIRMATION_PENDING, // Confirmation pending
  STATUS_DELIVERED, // Delivered
  STATUS_DELIVERY_MISSED, // Delivery missed
  STATUS_DELIVERY_PENDING, // Delivery pending
  STATUS_TRANSFER_PENDING, // Transfer pending
  STATUS_UPLOAD_PENDING, // Upload pending
];
